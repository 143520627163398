<template>
  <div class="flex justify-end py-4">
    <div class="pt-2 relative text-gray-600 w-full md:w-2/5">
      <button type="submit" class="absolute left-0 top-0 mt-5 ml-4" @click="search()">
        <svg class="text-gray-600 h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px"
             viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve"
             width="512px" height="512px">
          <path
              d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"/>
        </svg>
      </button>
      <input class="w-full border-2 border-gray-300 bg-white h-10 px-5 pl-12 rounded-lg text-sm focus:outline-none"
             aria-label="Search your transaction, an address or a block" v-model="keyword"
             type="search" name="search" placeholder="Search your transaction, an address or a block"
             @keyup.enter="search()">
    </div>
  </div>
</template>

<script>

export default {
  name: "Search",
  data() {
    return {
      keyword: ''
    }
  },
  methods: {
    isValidAddress() {
      return this.keyword.startsWith('cx') && this.keyword.length === 42
    },
    isValidTxnHash() {
      return this.keyword.startsWith('cx') && this.keyword.length === 66
    },
    isValidBlockHash() {
      return this.keyword.startsWith('000') && this.keyword.length === 64
    },
    search() {
      if (this.keyword) {
        if (this.isValidAddress()) {
          this.$router.push({name: 'address', params: {address: this.keyword}})
        } else if (this.isValidBlockHash()) {
          this.$router.push({name: 'blocks', params: {hash: this.keyword}})
        } else if (this.isValidTxnHash()) {
          this.$router.push({name: 'transactions', params: {hash: this.keyword}})
        }
      }
    }
  }
}
</script>

<style scoped>

</style>